import React, { useReducer, useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { Wrapper, Text, Icon } from '../../components/';
import { percentage, simpleCurrency } from '../../assets/services/formatters';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types'
import moment from 'moment';
import Pagination from './Pagination';
import InfoLabels from './InfoLabels';
import ActionsSection from './ActionsSection';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { momentOrDefault } from '../../assets/services/formatters/dates';

const propTypes = {
    data: PropTypes.array,

    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        format: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.string]),
        aggregateLabels: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({
            display: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }))]),
        filter: PropTypes.bool,
        sortable: PropTypes.bool
    })),

    headerProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
        fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        background: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })]),

    rowProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
        rankingRows: PropTypes.bool,
        fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    })]),

    isSelectable: PropTypes.bool,

    hasDelete: PropTypes.bool,

    hasSearch: PropTypes.bool,

    hasEmptyWarning: PropTypes.bool,

    filterTags: PropTypes.bool,

}

const defaultProps = {
    headerProps: false,
    rowProps: false,
    isSelectable: false,
    hasDelete: false,
    hasSearch: false,
    filterTags: false,
    hasEmptyWarning: true
}

const initialState = {
    data: [],
    sortConfig: null,
    selectedRows: [],
    deletedRows: [],
    openRows: {},
    filterOptions: {},
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, data: action.data };
        case 'SET_SORT_CONFIG':
            return { ...state, sortConfig: action.sortConfig };
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: action.selectedRows };
        case 'SET_FILTER':
            return { ...state, filterOptions: action.filterOptions };

        case 'UPDATE_ROW': {
            const formattedUpdatedValues = action.payload.reduce((prev, curr) => {
                prev[curr.uuid] = curr;
                return prev;
            }, {});

            const updatedIds = Object.keys(formattedUpdatedValues);

            const updatedData = state.data.map(item =>
                updatedIds.includes(item.uuid) ? formattedUpdatedValues[item.uuid] : item
            );

            return { ...state, data: updatedData };
        }
        case 'UPDATE_SELECTED_ROWS': {
            const updatedSelectedRows = [...state.selectedRows];

            action.selectedRows.forEach(row => {
                const index = updatedSelectedRows.findIndex(selectedRow => selectedRow.uuid === row.uuid);
                if (index === -1) {
                    updatedSelectedRows.push(row);
                } else {
                    updatedSelectedRows.splice(index, 1);
                }
            });

            return { ...state, selectedRows: updatedSelectedRows };
        }
        case 'UPDATE_DELETED_ROWS': {
            return { ...state, deletedRows: [...state.deletedRows, action.payload] }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const DataTable = ({ data, columns, pagination, headerProps, rowProps, hasDelete, hasEmptyWarning, isSelectable, hasSearch, hasMoreLabels, filterTags, hasExport, ...props }) => {
    const masterCheckboxRef = useRef(null);

    const [state, dispatch] = useReducer(reducer, initialState);

    const [searchInputValue, setSearchInputValue] = useState('');

    const [paginationSize, setPaginationSize] = useState(pagination)
    const [currentPaginationPage, setCurrentPaginationPage] = useState(0)
    
    const dropdownColumn = columns?.find(column => column?.type === 'dropdown');

    const onUpdateValue = useCallback((updatedObject) => {
        const updateValues = Array.isArray(updatedObject) ? updatedObject : [updatedObject]

        dispatch({ type: 'UPDATE_ROW', payload: updateValues });
    }, [dispatch]);

    const matchesFilters = useCallback((item) => {
        const currentFilters = state?.filterOptions?.filters || {}

        if (Object.keys(currentFilters).length === 0) {
            return true;
        }

        for (let key in currentFilters) {
            if (!currentFilters[key].length) continue;

            if (!item.hasOwnProperty(key) || !currentFilters[key].includes(item[key])) {
                return false;
            }
        }

        return true;
    }, [state.filterOptions])

    const matchesSearch = useCallback((item) => {
        const searchValue = searchInputValue.toLowerCase();

        if (hasSearch === true) {
            return Object.values(item).some(value =>
                String(value).toLowerCase().includes(searchValue)
            );
        }

        if (Array.isArray(hasSearch)) {
            return hasSearch.some(key =>
                String(item[key]).toLowerCase().includes(searchValue)
            );
        }

        return true;
    }, [searchInputValue])

    const sortedData = useMemo(() => {
        if (!state.sortConfig) {
            return state.data;
        }

        const { key, direction } = state.sortConfig;
        const sortedData = [...state.data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        return sortedData;
    }, [state.data, state.sortConfig]);

    const filteredData = useMemo(() => {
        return sortedData.filter(item => matchesFilters(item) && matchesSearch(item))
    }, [sortedData, matchesSearch, matchesFilters])

    const tableData = useMemo(() => {
        return filteredData?.slice(pagination ? (currentPaginationPage * paginationSize) : 0,
            pagination ? ((currentPaginationPage + 1) * paginationSize) : sortedData.length)
    }, [filteredData, paginationSize, currentPaginationPage])

    const paginationPages = useMemo(() => {
        return Math.ceil(filteredData?.length / paginationSize)
    }, [filteredData, paginationSize])
    
    const currencyColorFormat = (obj, key) => {
        const isPositive = Number(obj?.[key] ?? 0) > 0
        
        return (
            <Text color={isPositive ? 'avel' : 'danger'}>
                {simpleCurrency(obj?.[key])}   
            </Text>
        )
    }

    const formatController = useMemo(() => {
        return {
            currency: (obj, key) => { return simpleCurrency(obj[key]) },
            colorCurrency: (obj, key) => { return currencyColorFormat(obj[key]) },
            currencyUS: (obj, key) => { return simpleCurrency(obj[key], 'pt-BR', 'USD') },
            date: (obj, key) => { return momentOrDefault(obj[key], 'DD/MM/YYYY', '-') },
            truncate: (obj, key) => { return <Text truncate>{obj[key]}</Text> },
            percentage: (obj, key) => { return percentage(obj[key]) },
        }
    }, [])

    useEffect(() => {
        if (masterCheckboxRef.current) {
            const isSomeSelected =
                state.selectedRows.length > 0 &&
                state.selectedRows.length < state.data.length;
            masterCheckboxRef.current.indeterminate = isSomeSelected;
        }
    }, [state.selectedRows, state.data.length]);

    useEffect(() => {
        props.onFilterData && props.onFilterData(filteredData)
    }, [filteredData])

    useEffect(() => {
        setPaginationSize(pagination)
    }, [pagination])

    useEffect(() => {
        const identifiedData = data?.map((item) => {
            const updatedItem = {
                ...item,
                uuid: item.uuid || uuidv4()
            }

            if (isSelectable) {
                if (!updatedItem.hasOwnProperty('__isRowSelected$')) {
                    updatedItem.__isRowSelected$ = false
                }
            }

            return updatedItem
        });

        if (isSelectable) {
            const selectedData = identifiedData?.filter((item) => item?.__isRowSelected$)
            dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: selectedData });
        }

        dispatch({ type: 'SET_DATA', data: identifiedData });
    }, [data]);

    const handleSelectedRows = useCallback((itemSelected) => {
        props.onSelect && props.onSelect(itemSelected)

        const selectedData = {
            ...itemSelected,
            __isRowSelected$: !itemSelected?.__isRowSelected$
        }

        dispatch({ type: 'UPDATE_ROW', payload: [selectedData] });
        dispatch({ type: 'UPDATE_SELECTED_ROWS', selectedRows: [selectedData] });
    }, [tableData]);

    const handleToggleAllRows = useCallback(() => {
        const isAllSelected = tableData?.every((item) => !item?.__isSelectableRow$ || item?.__isRowSelected$)
        const selectedsData = tableData?.filter(item => !item.hasOwnProperty('__isSelectableRow$') || item?.__isSelectableRow$)?.map(
            (item) => ({ ...item, __isRowSelected$: !isAllSelected }))

        props.onSelect && props.onSelect(null, isAllSelected, selectedsData)

        dispatch({ type: 'UPDATE_ROW', payload: selectedsData });
        dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: isAllSelected ? [] : selectedsData });
    }, [tableData]);

    const handleDeletedRows = useCallback((deletedItem) => {
        props.onDelete && props.onDelete(deletedItem)
        dispatch({ type: 'UPDATE_DELETED_ROWS', payload: deletedItem.uuid });
    }, [tableData])

    useEffect(() => {
        return () => {
            props.onClose && props.onClose()
        }
    }, [])

    const isAutoLayoutTable = columns?.findIndex((column) => column?.colSpan) === -1

    return (
        <Wrapper flexbox column width='100%'>

            {
                (columns.some(col => col.hasOwnProperty('aggregateLabels')) || hasMoreLabels)  && (
                    <InfoLabels
                        data={state.data}
                        sortedData={sortedData}
                        columns={columns}
                        matchesSearch={matchesSearch}
                        matchesFilters={matchesFilters}
                        hasMoreLabels={hasMoreLabels}
                    />
                )
            }

            <Wrapper flexbox column width='100%' {...props}>

                <ActionsSection
                    hasSearch={hasSearch}
                    hasExport={hasExport}
                    columns={columns}
                    data={state.data}
                    setCurrentPaginationPage={setCurrentPaginationPage}
                    setDebounceInputValue={setSearchInputValue}
                    formatController={formatController}
                />

                    <Wrapper style={{ overflowX: 'auto', overflowY: 'hidden', transform: 'rotateX(180deg)' }}>
                        <table className='tw-table-new tw-table-new-hover' style={{ borderCollapse: 'collapse', width: '100%', tableLayout: isAutoLayoutTable ? 'auto' : 'fixed', transform: 'rotateX(180deg)' }}>

                            <thead>
                                <TableHeader
                                    sortedData={sortedData}
                                    columns={columns}
                                    matchesSearch={matchesSearch}
                                    setCurrentPaginationPage={setCurrentPaginationPage}
                                    dispatch={dispatch}
                                    state={state}
                                    handleToggleAllRows={handleToggleAllRows}
                                    isSelectable={isSelectable}
                                    headerProps={headerProps}
                                    searchInputValue={searchInputValue}
                                    dropdownColumn={dropdownColumn}
                                />
                            </thead>

                            <tbody>
                                <TableBody
                                    tableData={tableData}
                                    fullData={state.data}
                                    columns={columns}
                                    hasDelete={hasDelete}
                                    formatController={formatController}
                                    dispatch={dispatch}
                                    isSelectable={isSelectable}
                                    handleSelectedRows={handleSelectedRows}
                                    handleDeletedRows={handleDeletedRows}
                                    rowProps={rowProps}
                                    onUpdateValue={onUpdateValue}
                                    deletedRows={state.deletedRows}
                                    dropdownColumn={dropdownColumn}
                                />

                            </tbody>
                        </table>
                    </Wrapper>

                {
                    (hasEmptyWarning && tableData?.length === 0) && (
                        <Wrapper flexbox column width='100%' gap='small' center padding>

                            <Icon fill='text.dark' name={data?.length === 0 ? 'warning' : 'search_off'} size={72}></Icon>

                            {
                                data?.length === 0 ? (
                                    <Text color='text.dark' fontSize='tiny' medium>Sem dados para exibição</Text>
                                ) : (
                                    <>
                                        <Text color='text.dark' fontSize='tiny' medium>Nenhum resultado encontrado</Text>
                                        <Text textAlign='center' width='250px' color='text.dark' fontSize='tiny' regular>
                                            Experimente ajustar sua pesquisa ou filtros
                                        </Text>
                                    </>
                                )
                            }
                        </Wrapper>
                    )

                }

            </Wrapper>

            {
                pagination && (
                    <Pagination
                        paginationPages={paginationPages}
                        currentPaginationPage={currentPaginationPage}
                        paginationSize={paginationSize}
                        setPaginationSize={setPaginationSize}
                        setCurrentPaginationPage={setCurrentPaginationPage}
                    />
                )
            }
        </Wrapper >
    )
};

DataTable.propTypes = propTypes
DataTable.defaultProps = defaultProps

export default DataTable;
