import { useEffect, useState } from 'react';
import useImageCheck from '../../../Hooks/useImageCheck';

const CheckedImage = ({ width, height, src, secondSrc, defaultSrc, ...props }) => {
    const sources = [src, secondSrc, defaultSrc];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageSrc, setImageSrc] = useState(sources[currentIndex]);

    const handleImageError = () => {
        if (currentIndex < sources.length - 1) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            setImageSrc(sources[nextIndex]);
        }
    };

    useImageCheck(imageSrc, handleImageError);

    useEffect(() => {
        if (!src) return;
        setCurrentIndex(0);
        setImageSrc(sources[0]);
    }, [src, secondSrc, defaultSrc]);

    return (
        <img
            style={{ height, width }}
            src={imageSrc}
            onError={handleImageError}
            {...props}
        />
    );
};

export default CheckedImage;
