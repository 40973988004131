import { useEffect, useRef } from 'react';
import io from 'socket.io-client';

export const useSocket = (BASE_URL, details, onUpdate) => {
    const socketRef = useRef(null);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(BASE_URL, {
                path: '/socket.io',
                transports: ['websocket', 'polling'],
                reconnectionAttempts: 5,
                perMessageDeflate: false,
            });
        }

        const socket = socketRef.current;

        socket.on('connect', () => {
            console.log('Socket connected:');
        });

        socket.on('disconnect', (reason) => {
            console.warn(`Conexão desconectada: ${ reason }`);
        });

        socket.on('connect_error', (err) => {
            console.log('Message', err.message);
            console.log('Description', err.code);
            console.log('Error', err);
        });

        socket.emit('join-chat', details);

        socket.on('chat-update', (res) => {
            console.log('updated')
            onUpdate(res);
        });

        return () => {
            socket.emit('leave-chat', details);
            socket.disconnect();
        };
    }, []);

    return socketRef.current;
};