import React, { useState, useEffect, useContext, Suspense } from 'react'
import { AppContext } from '../Context';
import { Outlet } from 'react-router-dom'
import Cookies from 'js-cookie'
import { BASE_URL } from '../Enviroment';
import MaintenancePage from '../App/pages/Maintenance';
import { RestrictedAccess } from '../Template/Components';

const PrivateRoute = ({ pageId }) => {
    const { globalContext, setGlobalContext } = useContext(AppContext)

    const isHomePage = pageId === 0

    const [isLoading, setIsLoading] = useState(true)
    const [authVerification, setAuthVerification] = useState(false)
    const [sitetreeMaintenance, setSitetreeMaintenance] = useState(false)

    async function customFetch(endpoint, options = {}) {
        const urlComplete = `${BASE_URL}/auth/session`;
        const response = await fetch(urlComplete, options);

        if (response.ok) {
            console.log('Authorized!')
            return true
        }

        return false
    }

    const isAuth = async () => {
        const sessionid = Cookies.get('sessionid');
        // const sessionid = '57ednrkcmhxo2rynkodoow83q1xha3kc';
        const data = {
            token: sessionid
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        };

        const response = await customFetch('auth/session', requestInfo);

        return response
    }

    async function getUserSitetree() {
        const queryParams = { id: globalContext?.user?.id }

        const url = new URL(`${BASE_URL}/auth/sitetree`)
        url.search = new URLSearchParams(queryParams).toString()

        const response = await fetch(url.toString())
        const data = response.ok ? await response.json() : {}

        setGlobalContext(prevContext => { return { ...prevContext, sitetree: data } });
        return data
    }

    const asyncLoader = async () => {
        setGlobalContext(prevState => { return { ...prevState, selectedPageId: pageId } })
        const siteTree = await getUserSitetree()

        const accessTreeIds = siteTree?.validTreeIds

        const allPages = [...Object.values(globalContext?.sitetree?.tree)?.flatMap(item => item.options), ...globalContext?.sitetree?.unnestedLinks]
        const pageInfo = allPages?.find(page => page.id === pageId)
        
        setSitetreeMaintenance(pageInfo?.isOnMaintenance && globalContext?.user?.sector_key_id !== 1)
        
        const siteTreeVerificationValue = (accessTreeIds?.includes(pageId) || !pageId) || isHomePage

        setGlobalContext(prevState => { return { ...prevState, sitetreeVerification: siteTreeVerificationValue } })

        const authVerificationValue = await isAuth()

        setAuthVerification(authVerificationValue)

        setIsLoading(false)
    }

    useEffect(() => {
        asyncLoader()
    }, [])
    


    if (!globalContext?.sitetreeVerification) {
        return <RestrictedAccess />;
    }

    return (
        !isLoading && (
            authVerification ? sitetreeMaintenance ? <MaintenancePage/> : <Suspense><Outlet /></Suspense> : window.open(`${BASE_URL}/login/?next=/`, '_self')
        )
    )
}

export default PrivateRoute
