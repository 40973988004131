import React, { useEffect, useRef, useState } from 'react';
import { Modal, Wrapper, Text, Input, Button, Icon, Tooltip } from '../../../../../../../components';
import { BASE_URL } from '../../../../../../../Enviroment';
import { getFallback, postFallback } from '../../../../../../../assets/services/apis/api';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../../../../assets/theme';
import { simpleCurrency } from '../../../../../../../assets/services/formatters';

const rotate = keyframes`
    to {
      transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-radius: inherit;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #1DC077 0%, transparent 100%);
        animation: ${rotate} 0.5s infinite linear;
    }

    &:after {
        width: 85%;
        height: 85%;
        background-color: ${colors.getColor('background.06')};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const MigrateCustomerModal = ({ isOpen, closeModal, selectedUser, reloadAll }) => {

    const isInternationalSpecialist = selectedUser?.sectorId === 39

    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const [isVisibleWarningTooltip, setIsVisibleWarningTooltip] = useState(false)

    async function getCustomerInfo() {
        setIsLoading(true)

        const queryParams = { userId: selectedUser.id, customerId: inputValue }

        const url = new URL(`${BASE_URL}/commercial/customers/portfolio/customer/info`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        if (response.ok) {
            setSelectedCustomer(data)
        } else {
            setIsVisibleWarningTooltip(true)

            setTimeout(() => {
                setIsVisibleWarningTooltip(false)
            }, 3000)
        }

        setIsLoading(false)
    }

    async function migrateCustomer() {

        const body = {
            userId: selectedUser.id,
            customerId: selectedCustomer.customerCode
        }

        const url = `${BASE_URL}/commercial/customers/portfolio/migrate/customer`
        await postFallback(url, JSON.stringify(body), true, 'only-warning').then(response => {
            if (response.ok) {
                closeModal();
                reloadAll();
            }
        })
    }

    const checkInputValue = () => {
        if (!inputValue.length) {
            setIsVisibleWarningTooltip(true)

            setTimeout(() => {
                setIsVisibleWarningTooltip(false)
            }, 3000)
        } else {
            getCustomerInfo()
        }
    }

    useEffect(() => {
        setSelectedCustomer(null)
        setInputValue('')
    }, [isOpen])

    return (
        <Modal open={isOpen} close={closeModal}>
            <Wrapper position='relative' flexbox column width='30vw' bgColor='background.05' padding='1.5rem' gap='huge'>

                <Wrapper flexbox column gap='small'>
                    <Text strong fontSize='large'>Migrar cliente</Text>
                    <Text fontSize='tiny' color='text.dark'>Selecione um cliente para migrar para a sua base</Text>
                </Wrapper>

                <Wrapper flexbox gap align='end'>

                    <Tooltip description='Código Inválido' placement='bottom' visible={isVisibleWarningTooltip} zIndex={999999}>
                        <Input
                            placeholder='Adicionar código'
                            label='Código do cliente'
                            width='40%'
                            height='2.2rem'
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            type='number'
                        />
                    </Tooltip>

                    <Button height='2.2rem' size='mini' color='background.07' margin='0 0 0.1rem 0'
                        onClick={() => { checkInputValue() }}>
                        <Icon name='search' fill='text.dark' size={20} />
                    </Button>

                </Wrapper>

                <Wrapper flexbox center={!selectedCustomer || isLoading} height='10.5rem' width='100%' bgColor='background.06' padding='0.5rem'>
                    {
                        !isLoading && selectedCustomer ? (
                            <Wrapper flexbox column width='100%' gap='big'>

                                <Wrapper flexbox column>
                                    <Text fontSize='tiny' color='text.dark'>Nome</Text>
                                    <Text fontSize='small'>{selectedCustomer.customerName}</Text>
                                </Wrapper>

                                <Wrapper flexbox border='bottom' padding='bottom'>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='tiny' color='text.dark'>Cód. Cliente</Text>
                                        <Text fontSize='small'>{selectedCustomer.customerCode}</Text>
                                    </Wrapper>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='tiny' color='text.dark'>
                                            {isInternationalSpecialist ? 'Custódia Internacional' : 'Custódia'}
                                        </Text>
                                        <Text fontSize='small'>
                                            {simpleCurrency(isInternationalSpecialist ?
                                                selectedCustomer.internationalCustody : selectedCustomer.custody)}
                                        </Text>
                                    </Wrapper>

                                </Wrapper>

                                <Wrapper flexbox>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='tiny' color='text.dark'>Assessor Atual</Text>
                                        <Text fontSize='small'>{selectedCustomer.currentAdvisor}</Text>
                                    </Wrapper>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='tiny' color='text.dark'>Especialista Atual</Text>
                                        <Text fontSize='small'>{selectedCustomer.currentAttendant}</Text>
                                    </Wrapper>

                                </Wrapper>


                            </Wrapper>
                        ) : isLoading ? <LoaderWrapper /> : <Text fontSize='tiny' color='text.dark'>Adicione um código de cliente</Text>
                    }
                </Wrapper>

                <Wrapper flexbox align='center' justify='end'>

                    <Button kind='clean' onClick={() => closeModal()}>
                        <Text fontSize='small' color='text.dark'>Cancelar</Text>
                    </Button>

                    <Button color={!selectedCustomer ? 'gray.dark' : 'info'} size='small' onClick={() => { selectedCustomer && migrateCustomer() }}>
                        <Text>Migrar Cliente</Text>
                        <Icon name='south_west' fill='white' size={18} />
                    </Button>

                </Wrapper>

            </Wrapper>
        </Modal>
    )
}

export default MigrateCustomerModal