import { Wrapper, Text, Icon, Tooltip } from "../../../../components";
import { simpleCurrency } from "../currency";

export const customerNameFormat = (obj, key, openWallet) => {
    const customAvatar = obj.customerName ? obj.customerName.charAt(0) : '-';
    return (
        <Wrapper flexbox gap align='center' justify='initial'>

            <Wrapper flexbox center height='1.7rem' minWidth='1.7rem' corner='circle' border borderColor={obj?.groupColor}>
                <Text>{customAvatar}</Text>
            </Wrapper>

            <Text truncate>{obj.customerName || 'Sem Nome'}</Text>

            <Tooltip type="simple" isArrowType description='Perfil'>
                <Wrapper  flexbox bgHover='#252525' padding='0.2rem' corner='default' height='fit-content' center onClick={(event) => openWallet(event, obj.customerCode)}>
                    <Icon name='person' fill='white' size={20} />
                </Wrapper>
            </Tooltip>

        </Wrapper>
    );
}

export const currencyColorFormat = (obj, key) => {
    const isNumber = typeof obj?.[key] === 'number'
    const isPositive = isNumber ? (Number(obj?.[key] ?? 0) > 0) : '-'
    
    return (
        <Text color={isNumber ? isPositive ? 'avel' : 'danger' : ''}>
            {isNumber ? simpleCurrency(obj?.[key]) : '-'}   
        </Text>
    )
}

export const formatValue = (type, value) => {
    switch(type) {
        case 'currency': 
            return simpleCurrency(value)
        case 'number':
            return value
        default:
            return value
    }
}